import cn from 'classnames'

import Popup, { PopUpPlacementType } from '../popup'
import css from './tooltip.module.scss'

type TooltipProps = {
  tooltipPlacement: PopUpPlacementType
  children: JSX.Element
  text: string
  textSize?: 'small' | 'medium' | 'large'
  containerStyle?: React.CSSProperties
}

const Tooltip = ({ tooltipPlacement, children, text, textSize = 'medium', containerStyle }: TooltipProps) => (
  <div className={css.container} style={containerStyle}>
    <Popup offset={[0, 8]} placement={tooltipPlacement} eventType="hover" renderArrow className={css.popup}>
      {children}
      <div role="tooltip" className={cn([css.text, css[textSize]])} dangerouslySetInnerHTML={{ __html: text }}></div>
    </Popup>
  </div>
)

export default Tooltip
