import cn from 'classnames'

import css from './notification.module.scss'

type NotificationProps = {
  numberOfNotifications: number
  variant?: 'primary' | 'secondary' | 'tertiary'
  isAlert?: boolean
}

export const Notification = ({ numberOfNotifications, variant = 'primary', isAlert = false }: NotificationProps) => {
  return <span className={cn(css.container, css[variant], isAlert && css.alert)}>{numberOfNotifications}</span>
}
